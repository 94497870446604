<template>
  <div class="orders-bg">
    <div class="orders-container container">
      <div class="orders-top-content">
        <Menu></Menu>
      </div>
 
      <div class="orders-content px-5 py-4">
        <div class="orders-content-topbar pb-4" style="border-bottom: none">
          <div class="row justify-content-center">
            <div
              class="
                col-6 col-md-4
                text-start text-end text-sm-start
                order-2 order-sm-1
              "
            >
              <span
                class="
                  last-month
                  p-3
                  ps-5
                  text-dark
                  d-flex
                  align-middle
                  d-sm-inline-block
                  capitalize
                  cursor-pointer
                "
                @click="prevMonth()"
                ><p>{{ prevMonthDate | moment("MMMM YYYY") }}</p></span
              >
            </div>

            <div
              class="
                this-month
                col-12 col-md-4
                text-center
                fw-bold
                fs-4
                pt-3
                order-1 order-sm-2
                capitalize
              "
            >
              <p class="mb-3 mb-sm-0">{{ dateStart | moment("MMMM YYYY") }}</p>
            </div>
            <div
              class="col-6 col-md-4 text-start text-sm-end order-3 order-sm-3" @click="nextMonth()"
            >
              <span
                class="
                  next-month
                  p-3
                  pe-5
                  text-dark
                  d-flex
                  align-middle
                  d-sm-inline-block
                  capitalize
                  cursor-pointer
                "
                ><p>{{ nextMonthDate | moment("MMMM YYYY") }}</p></span
              >
            </div>
          </div>
        </div>

        <perfect-scrollbar
          class="navi navi-hover scroll mt-4 transactions-orders-table"
          style="position: relative"
        >
          <table class="w-100 table" v-if="transactions.data && transactions.data.length > 0">
            <thead>
              <tr>
                <th>{{ $t('transactions.label.payment_date') }}</th>
                <th>{{ $t('transactions.label.transaction_type') }}</th>
                <th>{{ $t('transactions.label.food_type') }}</th>
                <th>{{ $t('transactions.label.order_on_day') }}</th>
                <th>{{ $t('transactions.label.price') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="align-top" v-for="(transaction, index) in transactions.data" :key="index">
                <td>{{ transaction.created_at | moment("DD.MM.YYYY HH:mm:ss") }}</td>
                <td>{{ $t('transactions.title.type_' + transaction.type) }}</td>
                <td>
                    <span v-if="transaction.meal">{{ transaction.meal.food_type.name }}</span>
                    <span v-else class="text-muted">-</span>
                </td>
                <td>
                    <span v-if="transaction.meal">{{ transaction.meal.meal_date | moment("DD.MM.YYYY") }}</span>
                    <span v-else class="text-muted">-</span>
                </td>

                <td class="green-sum" v-if="transaction.type == 'DEPOSIT' || transaction.type == 'MEAL_PAYMENT_REFUND'">
                  +{{ transaction.amount }} Kč
                </td>
                <td class="red-sum" v-else>
                  -{{ transaction.amount }} Kč
                </td>

              </tr>
            </tbody>
          </table>
          <div v-else>
            <div class="mt-5 text-center text-muted">{{ $t('transactions.title.none_found') }}</div>
          </div>
        </perfect-scrollbar>
        <div style="border-top: 1px solid #dadada" class="mt-3 w-100"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";
import i18n from "@/plugins/vue-i18n";
import Menu from "@/views/core/Menu";
import moment from "@/plugins/moment";

export default {
  metaInfo() {
    return { title: i18n.t("meta.transactions") };
  },
  data() {
    return {
      transactions: null,
      page: 1,
      dateStart: moment(moment(this.dateStart).startOf('month')),
      actualWeek: moment(moment()).format("W"),
      actualYear: moment(moment(this.dateStart)).format("YYYY"),
      monthStart: moment(moment(this.dateStart).startOf('month')),
      monthEnd: moment(moment(this.dateStart).endOf('month')),
      nextMonthDate: moment(moment(this.dateStart).startOf('month')).add(1, "months"),
      prevMonthDate : moment(moment(this.dateStart).startOf('month')).subtract(1, "months"),
    };
  },
  components: {
    Menu
  },
  watch: {
    dateStart: function () {
      this.actualYear = moment(moment(this.dateStart)).format("YYYY");
      this.nextMonthDate = moment(moment(this.dateStart).startOf('month')).add(1, "months");
      this.prevMonthDate = moment(moment(this.dateStart).startOf('month')).subtract(1, "months");
      this.monthStart= moment(moment(this.dateStart).startOf('month'));
      this.monthEnd= moment(moment(this.dateStart).endOf('month'));
      this.getTransactions();
    },
  },
  mounted() {
    this.getLastSearch();
    this.getTransactions();
  },
  computed: {
    transactionSearch: {
      get () {
        return this.$store.state.transactions.transactionSearch;
      },
      set (value) {
        this.$store.commit('setTransactionSearch', value);
      }
    }
  },
  methods: {
    getTransactions() {
      this.loading = true;
      ApiService.setHeader();
      ApiService.apiGet(
        "diner/transfer-logs?from=" + moment(this.monthStart).format("YYYY-MM-DD") + "&to=" + moment(this.monthEnd).format("YYYY-MM-DD") + "&page="+ this.page +"&perpage=10000"
      ).then((response) => {
        this.transactions = response.data;
        this.loading = false;
      });
    },
    prevMonth()
    {
      this.dateStart = moment(
        moment(
          moment(this.dateStart).startOf('month')
            .subtract(1, "months")
        )
      );

      this.transactionSearch = {
        dateStart: this.dateStart
      };
    },
    nextMonth()
    {
      this.dateStart = moment(
        moment(
          moment(this.dateStart).startOf('month')
            .add(1, "months")
        )
      );

      this.transactionSearch = {
        dateStart: this.dateStart
      };
    },
    getLastSearch()
    {
      let lastSearch = this.$store.state.transactions.transactionSearch;
      if(lastSearch.dateStart != null)
      {
        this.dateStart = lastSearch.dateStart;
      }
    }
  },
};
</script>   